import $ from 'jquery'
import lazySizes from 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.js'
import Dropdown from 'bootstrap/js/dist/dropdown.js'
import Offcanvas from 'bootstrap/js/dist/offcanvas.js'
import Tab from 'bootstrap/js/dist/tab.js'
import Modal from 'bootstrap/js/dist/modal.js'
import Swiper, {Navigation, Lazy} from 'swiper'
import { Fancybox } from "@fancyapps/ui";

(() => {
    'use strict'
    window.$ = window.jQuery = $

    var fileInputs = document.querySelectorAll('.inputfile')
    Array.prototype.forEach.call(fileInputs, (input) => {
        var label = input.parentElement.querySelector('.caption') /*input.previousElementSibling*/
        var labelVal = label.innerHTML

        input.addEventListener('change', function (e) {
            var fileName = ''
            if (this.files && this.files.length > 1) {
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('%count%', this.files.length)
            } else {
                fileName = e.target.value.split('\\').pop()
            }

            if (fileName) {
                label.innerHTML = fileName
            } else {
                label.innerHTML = labelVal
            }
        });
    })

    const productSliders = document.querySelectorAll('.product-slider')
    productSliders.forEach((item) => {
        new Swiper(item, {
            modules: [Navigation],
            spaceBetween: 32,
            slidesPerView: 1,
            breakpoints: {
                992: {
                    slidesPerView: 4,
                },
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }
        })
    })


    const productGallerySliders = document.querySelectorAll('.product-gallery-slider')
    productGallerySliders.forEach((item) => {
        new Swiper(item, {
            modules: [Navigation, Lazy],
            spaceBetween: 16,
            slidesPerView: 1,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 2,
            },
            autoHeight: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }
        })
    })

    $(document).on('af_complete', function(event, response) {
        console.log(response)
        const form = $(response.form[0])
        const redirect = form.attr('data-redirect')

        if (response.success && redirect) {
            window.location.href = redirect
        }
    })
})()